import {
  Machine,
  assign
} from "xstate";
import {
  getTrucks,
  getFare,
  getRoles
} from "./api/get-thingy";
import axios from "axios";

// import { submitRequestForm } from "./signup";

function setTrucks(trucks) {
  const select = document.querySelector("[data-set-trucks]");

  for (var i = 0; i < trucks.length; i++) {
    var opt = document.createElement("option");
    opt.value = trucks[i].id;
    opt.innerHTML = `${trucks[i].name} - Capacity ${trucks[i].capacity}`;
    select.appendChild(opt);
  }
}

async function getDistance(pickup, destination) {
  let {
    data
  } = await axios({
    method: "get",
    url: `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=place_id:${pickup}&destinations=place_id:${destination}&key=${process.env.GOOGLE_MAPS_KEY}`,
  });
  let distance = data.rows[0].elements[0].distance.value;
  console.log(distance);
  return distance;
}

export const requestFormMachine = Machine({
  id: "requestform",
  initial: "editing",
  context: {
    pickup: undefined,
    destination: undefined,
    distance: undefined,
    truck_type_id: undefined,
    tonnage: undefined,
    value_of_goods: undefined,
    result: undefined,
  },
  states: {
    editing: {
      on: {
        SET_PICKUP: {
          actions: assign({
            pickup: (_, event) => event.placeId,
          }),
        },
        SET_DESTINATION: {
          actions: assign({
            destination: (_, event) => event.placeId,
          }),
        },
        SET_TRUCK_TYPE: {
          actions: assign({
            truck_type_id: (_, event) => event.value,
          }),
        },
        SET_TONNAGE: {
          actions: assign({
            tonnage: (_, event) => event.value,
          }),
        },
        SET_VALUE_OF_GOODS: {
          actions: assign({
            value_of_goods: (_, event) => event.value,
          }),
        },
        SUBMIT: "submitting",
        // SUBMIT: {
        //   invoke: {
        //     // id: "submit-request",
        //     src: (context, event) => console.log("submits"),
        //     // await getDistance(context.pickup, context.destination);

        //     onDone: {
        //       target: "submitted",
        //       actions: assign({
        //         distance: (_, event) => event.data,
        //       }),
        //     },
        //   },
        // },
      },
    },
    submitting: {
      invoke: {
        id: "submit-request",
        src: async (context, event) => {
          document.getElementById("submitRequestForm").innerHTML =
            "Submitting...";
          let quotePrice = document.getElementById("request-quote__value");

          let distance = await getDistance(context.pickup, context.destination);
          let values = {
            distance,
            truck_type_id: Number(context.truck_type_id),
            tonnage: Number(context.tonnage),
            value_of_goods: Number(context.value_of_goods),
          };
          let res = await getFare(values);

          // console.log(res);
          let {
            data: {
              price
            },
          } = res;

          // Format price
          let nf = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          quotePrice.innerHTML = `Price Estimate: <span class="text--bold text--dark-blue">₦${nf.format(
            price
          )}</span>`;
        },
        onDone: {
          target: "submitted",
          actions: assign({
            result: (_, event) => {
              event.result;
            },
          }),
        },
        onError: {
          target: "failure",
          // actions: () => console.log("error"),
          actions: (_, e) => console.error(e.data),
        },
      },
    },
    submitted: {
      // type: "final",
      invoke: {
        src: async (context, event) => {
          document.getElementById("submitRequestForm").innerHTML =
            "Submit Request";
        },
      },
      actions: assign({
        result: (_, event) => {
          event.result;
        },
      }),
    },
    failure: {
      actions: (_, e) => console.error(e.data),
      // invoke: {
      //   src: () => console.log("error"), // alert("Oops, something went wrong. Please try again later"),
      // },
    },
  },
});

export const truckMachine = Machine({
  id: "trucks",
  initial: "idle",
  context: {
    trucks: [],
  },
  states: {
    idle: {
      on: {
        FETCH: "loading",
      },
    },
    loading: {
      invoke: {
        id: "getTrucks",
        src: getTrucks,
        onDone: {
          target: "loaded",
          actions: assign({
            trucks: (_, event) => event.data,
          }),
        },
        onError: "failure",
      },
    },
    loaded: {
      invoke: {
        id: "setTrucks",
        src: (context, event) => setTrucks(context.trucks),
      },
      // on: {
      //   REFRESH: "loading",
      // },
    },
    failure: {
      // on: {
      //   RETRY: "loading",
      // },
    },
  },
});

const rolesMachine = Machine({
  id: "roles",
  initial: "idle",
  context: {
    roles: [],
  },
  states: {
    idle: {
      on: {
        FETCH: "loading"
      },
    },
    loading: {
      invoke: {
        src: getRoles,
        onDone: {
          target: "loaded",
          actions: assign({
            roles: (_, event) => event.data,
          }),
        },
        onError: "failure",
      },
    },
    loaded: {},
    failure: {},
  },
});

export const menuMachine = Machine({
  id: "menu",
  initial: "closed",
  states: {
    closed: {
      invoke: {
        id: "closeMenu",
        src: () =>
          document
          .querySelector(".navigation--mobile__menu")
          .classList.remove("navigation--mobile__menu--active"),
      },
      on: {
        TOGGLE: "open",
      },
    },
    open: {
      invoke: {
        id: "openMenu",
        src: () =>
          document
          .querySelector(".navigation--mobile__menu")
          .classList.add("navigation--mobile__menu--active"),
      },
      on: {
        TOGGLE: "closed",
      },
    },
  },
});
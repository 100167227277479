import client from "./client";

export async function getTrucks() {
  try {
    let { data } = await client.get(`/truck-types`);
    return data.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getRoles() {
  try {
    let { data } = await client.get(`/roles`);
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getFare(values) {
  try {
    let { data } = await client.post(`/payment/fare/calculate`, {
      ...values,
    });
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
    alert("Oops, something went wrong. Please try again.");
  }
}

import axios from "axios";

export const baseURL = `https://cors-anywhere.herokuapp.com/http://pine.africa/api`;

const apiClient = axios.create({
  baseURL: baseURL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default apiClient;

import { truckMachine, menuMachine, requestFormMachine } from "./app-state";
import { interpret } from "xstate";

const truckService = interpret(truckMachine);
const menuService = interpret(menuMachine);
const requestFormService = interpret(requestFormMachine, { devTools: true });

async function initRequestForm() {
  // GOOGLE MAPS SETUP
  var countryRestrict = { country: "ng" };
  var pickupAutocomplete;
  var destinationAutocomplete;

  var options = {
    types: ["establishment"],
    componentRestrictions: countryRestrict,
  };

  // Pickup
  var pickupInput = document.getElementById("pick-up-location");
  pickupAutocomplete = new google.maps.places.Autocomplete(
    pickupInput,
    options
  );
  pickupAutocomplete.setFields(["address_component", "place_id"]);
  pickupAutocomplete.addListener("place_changed", onPickupChanged);

  function onPickupChanged() {
    var placeId = pickupAutocomplete.getPlace().place_id;
    requestFormService.send("SET_PICKUP", { placeId });
  }

  // Destination
  var destinationInput = document.getElementById("destination-location");
  destinationAutocomplete = new google.maps.places.Autocomplete(
    destinationInput,
    options
  );
  destinationAutocomplete.setFields(["address_component", "place_id"]);
  destinationAutocomplete.addListener("place_changed", onDestinationChanged);

  function onDestinationChanged() {
    var placeId = destinationAutocomplete.getPlace().place_id;
    requestFormService.send("SET_DESTINATION", { placeId });
  }

  // Truck type
  var selectTruckType = document.getElementById("selectTruckType");
  selectTruckType.addEventListener("change", function (e) {
    requestFormService.send("SET_TRUCK_TYPE", { value: e.target.value });
  });

  // Tonnage
  var tonnage = document.getElementById("tonnage");
  tonnage.addEventListener("input", function (e) {
    console.log(e.target.value);
    requestFormService.send("SET_TONNAGE", { value: e.target.value });
  });

  // Value of goods
  var valueOfGoods = document.getElementById("valueOfGoods");
  valueOfGoods.addEventListener("input", function (e) {
    console.log(e.target.value);
    requestFormService.send("SET_VALUE_OF_GOODS", { value: e.target.value });
  });

  // SUBMIT
  var requestForm = document.getElementById("requestForm");
  requestForm.addEventListener("keypress", function (e) {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      return false;
    }
  });
  requestForm.addEventListener("submit", function (event) {
    event.preventDefault();
    var response = grecaptcha.getResponse();
    if (response.length === 0) {
      alert("Please complete the reCAPTCHA.");
      return false;
    } else {
      requestFormService.send("SUBMIT");
      return true;
    }
  });
}

async function initXState() {
  truckService.start();
  menuService.start();
  requestFormService.start();

  let select = document.querySelector("[data-set-trucks]");
  document.body.contains(select) ? truckService.send("FETCH") : null;

  let menuToggle = document.querySelector(".menu-button");
  menuToggle.addEventListener("touchstart", function () {
    menuService.send("TOGGLE");
  });
}

async function init() {
  initXState();
  initRequestForm();
}

init();
